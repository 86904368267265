#contact, #contact-gredient, #contact-colorfull, #contact-form-three {
    background: #f8f8f8; }

#contact {
    &.contact-single-one {
        padding-top: 0; }

    .google-map {
        padding: 0;
        margin-bottom: 50px; } }

#contact-three {
    background: #f8f8f8;
    .google-map {
        .gmap3-area {
            height: 90vh; } }

    .contact-wrapper {
        padding: 50px 30px; }


    .gp-contact-form-two {
        margin: 50px 0 0;
        .gp-btn {
            margin: 0; } }

    .contact-details {
        margin-bottom: 20px;
        .icon {
            margin-bottom: 10px;
            float: left;
            margin-right: 20px;
            i {
                font-size: 35px;
                color: $color_theme; } }
        .contact-content {
            margin-left: 60px; }

        h3 {
            font-size: 20px;
            color: #000; } } }

.contact-title {
    text-align: center;
    margin-bottom: 50px;

    h2 {
        color: #000; } }
.padding-none {
    padding: 0 !important; }

.contact-info {
    text-align: center;
    background: #FFF;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.01); }


.con-details {
    border-bottom: 1px solid #eaeaea;
    margin: 0;
    padding: 10px 0;

    .con-icon {
        height: 60px;
        width: 60px;
        text-align: center;
        margin: 0 auto;
        background: lighten($color_theme, 10%);
        border-radius: 50%;
        margin-bottom: 10px;

        i {
            line-height: 60px;
            font-size: 30px;
            color: #FFF; }
        &.color-one {
            background: $color-1; }

        &.color-two {
            background: $color-2; }

        &.color-three {
            background: $color-3; } }

    p {
        color: #444;
        margin-bottom: 0; }

    &:last-child {
        border-bottom: none; } }


.contact-form-area {
    padding: 0; }


#gp-contact-form, #gp-contact-form-two, #gp-contact-form-three, #gp-contact-form-four {
    .form-group {
        margin-bottom: 20px; }

    .form-control {
        height: 55px;
        border: none;
        background: #FFF;
        box-shadow: 0 10px 20px rgba(0,0,0,0.03);
        border-radius: 5px;
        font-size: 16px;
        border-radius: 30px;
        padding: 10px 25px; }

    textarea {
        &.form-control {
            height: 200px;
            padding: 20px;
            margin-bottom: 30px; } }

    .gp-btn {
        display: block;
        max-width: 300px;
        width: 100%;
        border-radius: 30px;
        border: none;
        background: #333;
        color: #FFF;
        transition: all 0.3s ease-in-out;
        outline: none;
        margin: 0 auto;
        cursor: pointer;

        &:hover {
            background: $color_theme; }

        &.btn-border-one {
            background: transparent;
            color: $color-1;
            border: 2px solid $color-1;

            &:hover {
                background: $color-1;
                color: #FFF;
                border-color: color-1; } } } }

#gp-contact-form .gp-btn.gredient {
    background: linear-gradient(180deg, #4772d9, #6d47d9);
    color: #FFF; }


.get-in-touch {
    padding: 30px;

    h4 {
        font-size: 16px;
        line-height: 24px;
        color: #444;
        font-weight: 500px;
        margin-bottom: 20px; } }


#contact-two {
    padding-bottom: 0;
    background: #f8f8f8; }

.gp-contact-form-two {
    margin: 0 auto 150px;
    max-width: 800px; }

#map {
    position: relative; }

.contact-details-two {
    position: absolute;
    top: 0;
    max-width: 1000px;
    width: 100%;
    z-index: 300;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    border-radius: 15px;
    box-shadow: 10px 10px 20px rgba(0,0,0, 0.1);
    padding: 70px 0;

    .contact-info-two {
        text-align: center;
        display: inline-block;
        width: 33.33%;
        float: left;
        border-right: 1px solid #f5f5f5;

        &:last-child {
            border-right: none; }

        i {
            font-size: 40px;
            display: block;
            color: $color_theme;
            margin-bottom: 15px; }

        p {
            font-size: 15px;
            margin-bottom: 0;
            line-height: 24px; } } }


.gmap3-area {
    height: 500px; }


.con-details {
    &.gredient {
       .con-icon {
            background: $color_gradian; } } }

@media (max-width: 768px) {
    .contact-details-two {
        position: relative;
        transform: translate(0);
        left: 0;
        box-shadow: none;
        max-width: 95%;
        margin: 0 auto 20px; }

    .gmap3-area {
        height: 300px; }

    .contact-details-two {
        .contact-info-two {
            float: none;
            display: block;
            width: 100%;
            border-right: 0; } }

    .gp-contact-form-two {
        margin: 0 auto 50px; } }



.page-overlay {
    width: 100%;
    background: #0d50aa;
    position: relative; }


.text {
    position: absolute;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translate(0,-50%); }

.text {
    >  p {
        font-size: 30px;
        color: #fff;
        text-align: center;
        font-family: "Playfair Display", serif; } }

.inside {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); }

.inside {
    > p {
        font-size: 6rem;
        color: #16a085;
        text-align: center;
        font-family: "Playfair Display", serif; } }


@-webkit-keyframes progress1 {
    0% {
        transform: scalex(0);
        opacity: .5; }

    90% {
        transform: scalex(1);
        opacity: 1; }

    92% {
        transform: scalex(1);
        opacity: 1; }

    100% {
        transform: scalex(1);
        opacity: 1; } }



@keyframes progress1 {
    0% {
        transform: scalex(0);
        opacity: .5; }

    90% {
        transform: scalex(1);
        opacity: 1; }

    92% {
        transform: scalex(1);
        opacity: 1; }

    100% {
        transform: scalex(1);
        opacity: 1; } }


@-webkit-keyframes progress2 {
    0% {
        transform: scale(0.3, 0.8) translatez(0);
        opacity: 0; }

    90% {
        transform: scale(1, 1) translatex(100vw) translatez(0);
        opacity: 1; }

    100% {
        transform: scale(1, 1) translatex(100vw) translatez(0);
        opacity: 1; } }


@keyframes progress2 {
    0% {
        transform: scale(0.3, 0.8) translatez(0);
        opacity: 0; }

    90% {
        transform: scale(1, 1) translatex(100vw) translatez(0);
        opacity: 1; }

    100% {
        transform: scale(1, 1) translatex(100vw) translatez(0);
        opacity: 1; } }


.paper-progress-bar {
    position: absolute;
    top: 55%;
    width: 100%;
    height: 3px;
    background: rgba(255, 255, 255, 0.1);
    transform: translate(0,-50%); }

.paper-progress-bar:before {
    animation: progress1 5.5s linear forwards;
    transform-origin: 0 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.4) 80%, white); }

.paper-progress-bar:after {
    content: "";
    position: absolute;
    animation: progress2 5.5s linear forwards;
    transform-origin: 90% 50%;
    margin-left: -24px;
    top: -9px;
    width: 30px;
    height: 21px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.55);
    filter: blur(8px);
    box-shadow: 0 0 10px 6px rgba(210, 189, 255, 0.4), -20px 0 15px 4px rgba(210, 189, 255, 0.3), -40px 0 15px 2px rgba(210, 189, 255, 0.2), -60px 0 10px 1px rgba(210, 189, 255, 0.1), -80px 0 10px 1px rgba(210, 189, 255, 0.05); }


#gp-contact-form-two {
    .form-control {
        &.bdrs-none {
            border-radius: 5px; } } }


@media (max-width: 768px) {
    .contact-info {
        width: 90%;
        margin: 0 auto; }

    .con-details {
        padding: 20px; } }
