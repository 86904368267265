#work-prosses {
    padding: 100px 0; }


.pss-wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
        display: inline-block;
        width: 25%;
        text-align: center;
        position: relative;


        &:after {
            position: absolute;
            content: '';
            width: 35px;
            height: 5px;
            background: #f3f3f3;
            right: -15.5px;
            top: 26%;
            border-radius: 30px; }

        &:last-child {
            &:after {
                display: none; } } }

    .pss-container {
        .icon-circle {
            height: 160px;
            width: 160px;
            background: #f3f3f3;
            border-radius: 50%;
            margin: 0 auto 20px;
            position: relative;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.01);
            overflow: hidden;

            i {
                font-size: 60px;
                color: #fff;
                line-height: 160px;
                color: $color_theme;
                z-index: 1;
                display: inline-block; } }

        &:hover {
            .icon-circle {
                background: $color_theme;

                i {
                    color: #fff;
                    animation: toRightFromLeft 0.3s forwards; } }

            .pss-text-area {
                h4 {
                    color: $color_theme; } } } } }


@keyframes toRightFromLeft {
    49% {
        transform: translate(100%); }

    50% {
        opacity: 0;
        transform: translate(-100%); }

    51% {
        opacity: 1; } }

@keyframes toRightFromTop {
    49% {
        transform: translateY(-100%); }

    50% {
        opacity: 0;
        transform: translateY(100%); }

    51% {
        opacity: 1; } }

.pss-text-area {
    h4 {
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 10px;
        font-family: $font_header;
        letter-spacing: 1px;
        transition: all 0.3s ease-in-out; }

    .count {
        font-size: 20px;
        font-weight: 600;
        color: $color_theme;
        display: block;
        margin-bottom: 15px; } }

/* Work Stape Two */
.work-prosses {
    &#work-prosses-two {
        background: #f5f5f5;
        padding: 100px 0; } }



.gp-process-steps {
    &.gp-process-steps-two {
        .icon-circle {
                background: #FFF; } } }


/* Prosses Colorfull */
.pss-container {
    &.color-one, {
        .icon-circle {
            i {
                color: $color-1; } }

        .pss-text-area {
            .count {
                color: $color-1; } } } }

.pss-container {
    &.color-two, {
        .icon-circle {
            i {
                color: $color-2; } }

        .pss-text-area {
            .count {
                color: $color-2; } } } }

.pss-container {
    &.color-three, {
        .icon-circle {
            i {
                color: $color_theme; } }

        .pss-text-area {
            .count {
                color: $color_theme; } } } }

.pss-container {
    &.color-four, {
        .icon-circle {
            i {
                color: $color-3; } }

        .pss-text-area {
            .count {
                color: $color-3; } } } }



.pss-container {
    &.gredient {
        .icon-circle {
            i {
                background: $color_gradian;
                color: #FFF;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; } }

        .pss-text-area {
            .count {
                background: $color_gradian;
                color: #FFF;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; } }

        &:hover {
        	.icon-circle {
        		background: $color_gradian;
	        	i {
	        		color: #fff;
	        		-webkit-text-fill-color: #fff; } } } } }


@media screen and (max-width: 1024px) {
    .pss-wrapper {
        li {
            width: 50%;
            padding: 0 20px;
            margin-bottom: 30px;

            &:nth-child(2) {
                &:after {
                    display: none; } } } } }

@media screen and (max-width: 768px) {
    .pss-wrapper {
        li {
            margin-bottom: 20px; } } }




@media screen and (max-width: 500px) {
    .pss-wrapper {
        li {
            width: 100%;

            &:after {
                display: none; } } } }
