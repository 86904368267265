#countup, #countup-two {
	position: relative; }


#countup-two {
	background: #f5f5f5; }

.counter-box, .counter-box-two {
	.count {
		overflow: hidden;

		i {
			font-size: 45px;
			margin-bottom: 10px;
			color: #FFF;
			display: block;
			display: inline-block; }

		h2 {
			font-size: 50px;
			font-weight: 700;
			color: $color_theme; }

		p {
			color: #FFF;
			font-size: 16px; } }

	&:hover {
		.count {
			i {
				animation: toRightFromLeft 0.3s forwards; } } }

	&.color-one {
		padding: 30px 20px;
		border: 1px solid $color-1;
		box-shadow: 0 0px 60px rgba(29, 27, 27, 0.2);

		.count {

			i {
				color: $color-1; }

			h2 {
				color: $color-1; }

			p {
				color: #000; } } }

	&.color-two {
		padding: 30px 20px;
		border: 1px solid $color-2;
		box-shadow: 0 0px 60px rgba(29, 27, 27, 0.2);

		.count {

			i {
				color: $color-2; }

			h2 {
				color: $color-2; }

			p {
				color: #000; } } }

	&.color-three {
		padding: 30px 20px;
		border: 1px solid $color-3;
		box-shadow: 0 0px 60px rgba(29, 27, 27, 0.2);

		.count {

			i {
				color: $color-3; }

			h2 {
				color: $color-3; }

			p {
				color: #000; } } }

	&.color-four {
		padding: 30px 20px;
		border: 1px solid $color-4;
		box-shadow: 0 0px 60px rgba(29, 27, 27, 0.2);

		.count {

			i {
				color: $color-4; }

			h2 {
				color: $color-4; }

			p {
				color: #000; } } } }

.counter-box-two {
	.count {

		i {
			color: #202020; }

		p {
			color: #202020; } } }

@media screen and (max-width: 992px) {
	.counter-box {
		margin: 0 auto 30px; } }

@media screen and (max-width: 768px) {
	.counter-box {
		max-width: 450px; } }
