#blog {}

.blog-post {
	background: #f5f5f5;
	margin-bottom: 20px;

	&.thumb-left {
		.blog-thumb {
			float: left; }

		.blog-content {
			margin-left: 380px; } }

	&.thumb-right {
		.blog-thumb {
			float: right; }

		.blog-content {
			margin-left: 0;
			margin-right: 380px; } }


	.blog-thumb {
		position: relative;

		img {
			width: 100%; }

		.date {
			height: 55px;
			width: 55px;
			background: rgba($color_theme,0.8);
			position: absolute;
			right: 20px;
			bottom: 20px;
			font-size: 15px;
			font-weight: 700;
			color: #FFF;
			text-align: center;
			border-radius: 3px;
			padding: 4px;

			span {
				font-size: 25px;
				font-weight: 800;
				position: relative;

				&:after {
					position: absolute;
					left: 0;
					bottom: 2px;
					width: 30px;
					height: 1px;
					background: #FFF;
					content: ''; } } } }

	.blog-content {
		min-height: 300px;
		padding: 23px 20px;

		&.blog-padding {
			padding: 41px 30px; }

		.post-meta {
			margin-bottom: 10px;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				margin-right: 20px;
				font-weight: 600;
				color: #444;
				position: relative;
				font-size: 15px;

				&:last-child {
					margin-right: 0; }

				&:first-child:after {
					position: absolute;
					right: -20px;
					top: 0;
					content: 'In';
					color: #000; }

				a {
					color: $color_theme;
					transition: all 0.3s ease-in-out;
					font-weight: 300;
					font-style: italic;

					&:hover {
						color: #000; } } } }

		h3 {
			font-size: 22px;
			margin-bottom: 20px;

			a {
				color: #444;
				font-weight: 700;


				&:hover {
					color: $color_theme; } } }

		p {
			margin-bottom: 15px; } } }

.read-more-btn {
	font-size: 15px;
	color: $color_theme;
	font-weight: 500;
	font-style: italic;

	&:hover {
		color: #000;
		text-decoration: underline; } }

#blog-two {
	background: #f8f8f8;
	padding: 100px 0; }

.blog-post-2 {
	background: #FFF;
	border-radius: 10px;

	.blog-thumb {
		position: relative;
		overflow: hidden;

		img {
			width: 100%;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px; }

		.blog-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;

			&:after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(255,255,255,0.5);
				content: '';
				-webkit-transition: -webkit-transform 0.6s;
				transition: transform 0.6s;
				-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
				transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0); } } }

	.blog-content {
		padding: 20px;

		h3 {
			font-size: 22px;
			margin-bottom: 15px; }

		.post-meta {

			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				margin-right: 10px;
				color: $color_theme;
				font-style: italic;
				position: relative;
				font-size: 13px;

				&:after {
					position: absolute;
					content: '/';
					right: -10px;
					top: 0;
					font-size: 12px; }

				&:last-child {
					&:after {
						display: none; } }

				a {
					color: $color_theme;
					font-weight: 500;
					transition: all 0.3s ease-in-out;

					&:hover {
						color: #000; } } } }

		h3 {
			a {
				color: #444;
				font-weight: 600;
				transition: all 0.3s ease-in-out;

				&:hover {
					color: $color_theme; } } }


		p {
			margin-bottom: 15px; } }

	&:hover {
		.blog-thumb {
			.blog-overlay {
				&:after {

					transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0); } } } }

	&.color-one {
		.blog-content {
			.post-meta {
				li {
					a {
						color: $color-1;

						&:hover {
							color: #000; } } } }

			h3 {
				a {
					&:hover {
						color: $color-1; } } } }

		.read-more-btn {
			color: $color-1;

			&:hover {
				color: #202020; } } }

	&.color-two {
		.blog-content {
			.post-meta {
				li {
					a {
						color: $color-2;

						&:hover {
							color: #000; } } } }

			h3 {
				a {
					&:hover {
						color: $color-2; } } } }

		.read-more-btn {
			color: $color-2;

			&:hover {
				color: #202020; } } }

	&.color-three {
		.blog-content {
			.post-meta {
				li {
					a {
						color: $color-3;

						&:hover {
							color: #000; } } } }

			h3 {
				a {
					&:hover {
						color: $color-3; } } } }

		.read-more-btn {
			color: $color-3;

			&:hover {
				color: #202020; } } } }


/*=========  Blog Banner  ==========*/
.page-banner {
	height: 550px;
	position: relative;
	padding: 100px 0; }

.breadcrumbs-inner {
	height: 385px;
	position: relative; }

.breadcrumb-inner-wrap {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -50%);

	.breadcrumbs-title {
		font-size: 50px;
		font-weight: 700;
		color: #FFF;
		margin: 0 0 10px; }

	.breadcrumbs-holder {
		margin-bottom: 10px;
		a {
			color: #FFF;
			font-size: 14px;

			&:hover {
				color: $color_theme; } }

		span {
			font-size: 14px;
			color: #FFF; } }


	h3 {
		color: #FFF;
		font-size: 20px; } }


/*=========  Blog Standard  ==========*/
.blog-standard {
	padding: 70px 0;
	background: #f8f8f8; }

.blog-post-standard {
	background: #FFF;
	border-radius: 10px;
	margin-bottom: 30px;

	.blog-thumb {
		img {
			width: 100%;
			height: auto;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px; } }

	.entry-content {
		padding: 30px 40px 0 40px;

		.blog-post-title {
			font-size: 24px;
			margin-top: 0;
			margin-bottom: 10px;
			font-weight: 700;

			a {
				color: #333;

				&:hover {
					color: $color_theme; } } }

		.page-content {
			margin-bottom: 20px;

			p {
				color: #808285; } } }

	.entry-meta {
		border-top: 1px solid #e2e2e2;
		padding: 15px 0;

		span {
			margin-right: 10px;
			font-size: 14px;
			font-weight: 600px;

			i {
				margin-right: 3px;
				color: $color_theme;
				font-size: 14px; }

			a {
				color: #333;
				transition: all 0.3s ease-in-out;

				&:hover {
					color: $color_theme; } } }

		.post-count {
			float: right; } } }

.pagination {
	ul {
		margin: 50px 0 0;
		padding: 0;
		list-style: none;
		text-align: center;

		li {
			display: inline-block;

			a {
				font-size: 16px;
				height: 40px;
				width: 40px;
				border: 1px solid #333;
				border-radius: 50%;
				display: inline-block;
				line-height: 40px;
				margin: 3px;
				transition: all 0.3s ease-in-out;
				color: #333;

				&.current, &:hover {
					background: $color_theme;
					border-color: $color_theme;
					color: #FFF; } }


			&:first-child {
				float: left;
				a {
					font-size: 18px; } }

			&:last-child {
				float: right;
				a {
					font-size: 18px; } } } } }


/*=========  Blog Grid  ==========*/
.blog-grid {
	background: #f8f8f8;
	padding: 70px 0;

	.blog-post-2 {
		margin-bottom: 30px; } }


/*=========  Blog List  ==========*/
.blog-list {
	padding: 70px 0;

	.blog-post {
		// background: #FFF
		margin-bottom: 30px; } }


/*=========  Blog Single  ==========*/
.blog-single {
	padding: 70px 0;
	background: #f8f8f8;

	.blog-content {
		p {
			margin-bottom: 20px; } } }

.gp-blockquote {
	background: #f8f8f8;
	border-left: none;
	padding: 40px;
	background: $color_theme;

	p {
		font-size: 18px;
		font-weight: 600px;
		line-height: 24px;
		font-style: italic;
		color: #FFF; }

	span {
		font-size: 14px;
		display: block;
		position: relative;
		margin-left: 15px;
		color: #f8f8f8;

		&:before {
			position: absolute;
			content: '';
			width: 10px;
			height: 1px;
			left: -15px;
			background: #f8f8f8;
			top: 50%;
			transform: translateY(-50%); } } }


.entry-author {
	background: #FFF;
	padding: 30px;
	margin-top: 50px;
	border-radius: 10px;

	.author-avatar {
		height: 100px;
		width: 100px;
		float: left;

		img {
			width: 100%;
			height: author;
			border-radius: 50%; } }

	.author-description {
		margin-left: 120px;

		.author-name {
			font-size: 17px;
			font-weight: 700;
			color: #000;
			margin-bottom: 10px; }

		.author-biographical-info {
			margin-bottom: 10px;

			p {
				color: #444;
				margin-bottom: 0;
				line-height: 26px;
				font-size: 15px; } }

		.author-social-networks {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;

				a {
					color: #000;
					text-decoration: none;
					display: block;
					margin-right: 5px;
					font-size: 16px;

					&:hover {
						color: $color_theme; } } } } } }

.gp-post-info-bottom {
	padding-top: 20px;
	margin-top: 30px;
	border-top: 1px solid #c4c4c4;

	.tagcloud {
		a {
			font-size: 10px;
			line-height: 25px; }

		span {
			float: left;
			margin-right: 10px;
			font-weight: 700; } } }

.gp-blog-share-link {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-right: 5px;

		a {
			display: block;
			height: 30px;
			width: 30px;
			border-radius: 50%;
			text-align: center;
			line-height: 30px;
			color: #fff;
			transition: all 0.3s ease-in-out;

			&.facebook-bg {
				background: #305fb3; }

			&.twitter-bg {
				background: #5eaade; }

			&.google-plus-bg {
				background: #d7482a; }

			&.pinterest-bg {
				background: #bd081c; }

			&:hover {
				background: #111;
				color: #FFF; } } } }


/* Releted Post */
.releted-post {
	margin-top: 50px;

	.related-post-title {
		font-size: 22px;
		font-weight: 700;
		color: #333;
		margin-bottom: 30px; }

	.blog-post-2 {
		.blog-content {
			padding: 15px 20px;

			.post-meta {
				margin: 0 0 5px 0;

				li {
					font-size: 12px; } }

			h3 {
				line-height: 25px;
				font-size: 18px; } } } }


/* Blog Full Width */
.blog-fullwidth {
	width: 83%;
	margin: 0 auto; }



/* Comment List */
#comments {
	margin-top: 50px;
	padding-top: 50px;
	border-top: 1px solid #ccc;

	.gp-form-heading {
		font-size: 24px;
		font-weight: 700;
		color: #333; }

	.commentlist {
		margin: 50px 0 0;
		padding: 0;
		list-style: none;

		> li {
			&.comment {
				padding-bottom: 45px;
				margin-bottom: 45px;
				border-bottom: 1px solid #e7e7e7;

				.comment {
					margin-top: 50px; } } }


		.comment {

			div {
				&.clearfix {
					.avatar-box {
						position: relative;
						overflow: hidden;
						width: 80px;
						height: 80px;
						float: left;
						border-radius: 50%;

						img {
							width: 100%;
							height: auto; } }

					.comment-content {
						margin-left: 100px;

						.comment-author {
							margin-bottom: 10px;

							.entry-meta {
								color: #7b7b7b; }
							.author {
								float: left;
								font-size: 15px;
								line-height: inherit;
								margin-right: 20px;
								color: #111;
								font-size: 16px;
								font-weight: 700; }

							.reply {
								float: right;
								line-height: inherit;

								.comment-reply-link {
									font-size: 12px;
									color: #444;
									text-decoration: none;
									background: #ececec;
									display: block;
									padding: 3px 10px;
									border-radius: 30px;

									&:hover {
										background: #333;
										color: #FFF; } } } } } } }



			ul {
				margin-left: 70px;
				padding: 0;
				list-style: none; } } } }

/* Comment Form */
#respond {
	margin-top: 50px;

	.gp-form-heading {
		font-size: 26px;
		font-weight: 700; }

	form > {
		*.comment-form-author, *.comment-form-email, *.comment-form-url {
			width: 33.33%;
			float: left;
			border-radius: 30px;

			input {
				border-radius: 5px;
				outline: none;
				padding: 12px 20px;
				background: #FFF;
				width: 100%; } }

		* {
			padding: 0 10px; }

		.comment-form-comment {
			width: 100%;

			textarea {
				border-radius: 10px;
				outline: none;
				padding: 20px;
				background: #FFF !important;
				width: 100%; } } }

	.form-submit {
		margin: 15px 0 0;
		.gp-btn {
			display: block;
			width: 300px;
			background: #333;
			border: none;
			transition: all 0.3s ease-in-out;
			color: #FFF;
			border-radius: 30px;
			outline: none;
			cursor: pointer;

			&:hover {
				background: $color_theme;
				color: #FFF; } } } }




/*=========  Widgets  ==========*/
.widget {
	margin-bottom: 40px;

	.widget-title {
		font-size: 20px;
		font-weight: 600;
		color: #333;
		margin-bottom: 20px;
		padding-bottom: 10px;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			height: 3px;
			width: 40px;
			border: 1px solid #444;
			background: #FFF;
			border-radius: 5px;
			left: 0;
			bottom: 1px;
			z-index: 50; }

		&:after {
			position: absolute;
			content: '';
			width: 40%;
			height: 1px;
			background: #444;
			left: 0;
			bottom: 2px; } } }


/* Widget Search */

.widget_search {
	.search-form {
		position: relative;
		display: flex;
		border-radius: 30px;
		background: #FFF;
		box-shadow: 0 10px 20px rgba(0,0,0,0.09);

		label {
			margin-bottom: 0; }

		.search-field {
			background: transparent;
			border: none;
			margin-bottom: 0;
			outline: none;
			padding: 12px 20px;
			font-size: 14px;
			font-weight: 400; }

		.search-icon {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			transition: all 0.3s ease-in-out; }

		.search-submit {
			width: 50px;
			background-color: transparent;
			text-indent: 100px;
			margin-bottom: 0;
			border: none;
			outline: none; }

		&:hover {
			.search-icon {
				color: $color_theme; } } } }

/* Widget Recent Post */
.widget_recent_entries {
	.recent-post {
		border-color: #e4e4e4;
		a {
			text-decoration: none; }

		.post-content {
			h3 {
				color: #333;
				font-weight: 500;
				font-size: 14px; }

			.post-meta {
				p {
					color: #848484; } } } } }


/* Widget categories */
.gp_custom_menu {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin-bottom: 10px;

		a {
			font-size: 14px;
			font-weight: 500;
			text-decoration: none;
			display: block;
			line-height: 20px;
			color: #444;
			display: block;


			span {
				float: right; }

			&:hover {
				color: $color_theme; } } } }

/* Widget Tags */
.tagcloud {

	a {
		position: relative;
		float: left;
		font-size: 12px;
		margin-top: 0;
		margin-bottom: 7px;
		margin-right: 7px;
		background: #e7e7e7;
		border-radius: 4px;
		line-height: 30px;
		padding: 0px 15px;
		transform: perspective(0) translateY(0);
		transition: all .3s ease-in-out;
		color: #3a3a3a;
		text-transform: uppercase;
		font-weight: 500;

		&:hover {
			background: #fff;
			transform: translateY(-2px);
			box-shadow: 0 8px 20px 0 rgba(0,0,0,0.2); } } }


/* Page Pagination */
.page-pagination {
	text-align: center;
	margin-top: 50px;

	.pagination {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;


			a {
				display: block;
				height: 35px;
				width: 35px;
				line-height: 35px;
				border: 1px solid #c1c1c1;
				color: #c1c1c1;
				border-radius: 50%;
				margin: 0 3px;
				background-color: transparent;


				&:hover, &.active {
					background: $color_theme;
					border-color: $color_theme;
					color: #FFF; } } } } }

@media (min-width: 992px) and (max-width: 1200px) {

	.blog-post.thumb-left, .blog-post.thumb-right {
		.blog-thumb {
			width: 50%; } }

	.blog-post.thumb-left {
		.blog-content {
			margin-left: 300px; } }

	.blog-post.thumb-right {
		.blog-content {
			margin-right: 300px; } }

	.blog-post .blog-content.blog-padding {}


	.blog-post {
		.blog-content {
			min-height: 240px;
			padding: 10px 20px 0;

			.post-meta {
				li {
					a {
						font-size: 12px; } } }

			h3 {
				margin-bottom: 10px;
				font-size: 18px; }

			p {
				font-size: 12px;
				margin-bottom: 0; }

			.read-more-btn {
				font-size: 13px; }

			&.blog-padding {
				padding: 20px; } } } }


@media screen and (max-width: 992px) {
	.blog-post {
		.blog-content {
			&.blog-padding {
				min-height: 255px; } } }

	.blog-post-2 {
		margin-bottom: 30px; } }


@media screen and (max-width: 768px) {
	.blog-post, .blog-post-2 {
		max-width: 500px;
		margin: 0 auto 20px;

		&.thumb-left, &.thumb-right {
			.blog-thumb {
				float: none; }

			.blog-content {
				margin: 0; } } }

	.page-banner {
		height: 400px;
		padding: 55px 0; }

	.breadcrumbs-inner {
		height: 330px; }

	.breadcrumb-inner-wrap {
		.breadcrumbs-title {
			font-size: 30px; } } }

@media screen and (max-width: 576px) {

	.breadcrumb-inner-wrap {
		.breadcrumbs-holder {
			a {
				font-size: 12px; } }

		.breadcrumbs-title {
			font-size: 25px; } } }

