#error {
    height: 100vh;
    align-items: center;
    display: flex; }

.errer-page-inner {
    text-align: center;
    h1 {
        font-size: 200px;
        line-height: 200px;
        color: #fff;
        margin-bottom: 30px; }

    h2 {
        color: 40px;
        margin-bottom: 20px;
        color: #fff; }

    .content {
        p {
            color: #fff;
            font-size: 16px;
            line-height: 28px;
            margin: 0; } }


    .gp-btn {
        display: inline-block;
        background: $color_theme;
        margin-top: 30px;
        border-radius: 5px;
        border: 2px solid #fff;
        color: #fff;

        &:hover {
        	background: #000;
        	color: #fff; } } }
