#skills {
	padding: 100px 0; }

.skill-bar, .skill-bar-two {
	text-align: left;
	margin: 50px 0 0 0;
	padding-left: 0;
	list-style: none;
	max-width: 600px;

	li {
		&.progress {
			border-radius: 0;
			height: 18px;
			margin-bottom: 40px;
			overflow: visible;
			box-shadow: none;
			background: #eee;
			margin-bottom: 50px;
			border-radius: 20px;
			display: inherit; }

		.skill-bar-wrap {
			margin-bottom: 20px;
			box-shadow: none; }

		.progress-bar {
			height: 18px;
			width: 0;
			display: block;
			text-align: right;
			overflow: visible !important;
			position: relative;
			box-shadow: none;
			border-radius: 20px;
			opacity: 1;
			background: $color_theme;


			&:after {
				position: absolute;
				content: '';
				top: -8px;
				right: 20px;
				content: '';
				width: 1px;
				height: 6px;
				background: #a5a5a5; } }



		.skill-name, .percentage {
			color: #000;
			position: relative;
			top: -25px;
			font-size: 16px; }

		.skill-name {
			position: absolute;
			left: 0;
			font-weight: 700;
			font-size:  16px; } } }


#skill-bar-two {
	padding-left: 50px; }


.skill-bar-two {

	li {
		.progress-bar {
			height: 5px; }

		&.progress {
			height: 5px; } } }


/* Accordian */
.panel-group .card {
	background: none;
	border: none;
	border-radius: 0;
	box-shadow: none; }


.panel-group .card-header {
	padding: 0;
	background: none;
	border: 0;
	position: relative; }

.panel-group .card-header h5 {
	color: #303030;
	font-size: 16px;
	line-height: 1;
	border-radius: 0;
	transition: all 150ms ease-in-out;
	background: rgba(30, 30, 30, 0.1); }

.panel-group h5 > button {
	display: block;
	padding: 18px 20px;
	color: #303030;
	font-size: 16px;
	font-weight: 600;
	width: 100%;
	text-align: left;
	border: 0;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	outline: 0; }

.panel-group .card-active button,
.panel-group h5 > button:hover,
.panel-group h5 > button:focus {
	background: $color_theme;
	border: 0;
	color: #fff;
	position: relative;
	text-decoration: none; }

.panel-group .card-active button:after {
	left: 25px; }

.panel-default > .card-header + .panel-collapse .card-body {
	border: 0; }

.card-body {
	padding: 20px;
	background: #f5f5f5; }

.acc-border-radius .panel-group h5 > button {}

.acc-border-radius .panel-group h5,
.acc-border-radius .panel-group .card-active button,
.acc-border-radius .panel-group h5 > button:hover,
.acc-border-radius .panel-group h5 > button:focus {
	border-radius: 30px; }

.acc-border-radius .card-body {
	background: transparent; }

#skills-two {
	padding: 0;

	#skill-bar {
		padding-left: 50px; } }

#skills-colorfull {
	padding: 0;

	#skill-bar {
		padding-right: 50px; } }

.collapse {
	&.show {
		margin-bottom: 5px; } }

.skill-bar {
	&.gradients {
		li {
			.progress-bar {
				background: $color_gradian; } } }

	li {
		.progress-bar {
			&.color-one {
				background: $color-1; }

			&.color-two {
				background: $color-2; }

			&.color-three {
				background: $color-3; }

			&.color-four {
				background: $color-4; } } } }


.flex-wrap {
	display: flex;
	align-items: center; }

.skills-thumb {
	img {
		width: 100%; } }


h5 > button:before, h5 > button.collapsed:before {
	display: block;
	position: absolute;
	font-size: 25px;
	top: 16px;
	right: 20px;
	z-index: 9;
	font-family: FontAwesome;
	transition: all 0.5s;
	font-family: FontAwesome;
	font-size: 20px; }


h5 > button:before {
	content: "\f107"; }

h5 > button.collapsed:before {
	content: "\f105"; }


@media (max-width: 1024px) {
	#skills-colorfull {
		.skill-bar {
			padding: 50px 0 0; } }

	#skill-bar-two {
		padding: 50px 30px; } }

@media (max-width: 768px) {

	#choose {
		margin-bottom: 30px; } }
