.elements-banner {
    padding: 200px 0;
    position: relative;

    .breadcrumbs-inner {
        h1 {
            font-size: 60px;
            color: #FFF;
            margin-bottom: 20px; }

        h3 {
            font-size: 22px;
            color: #c1c1c1; } } }

.style-one {
    padding: 100px 0; }

.style-two {
    padding: 100px 0;
    background: #f8f8f8; }

.style-three {
    padding: 100px 0;
    background: $color_theme; }

.style-four {
    padding: 100px 0;
    background: #252525; }

.style-five {
    padding: 100px 0;
    background: #f8f8f8; }


/* Accordian */
#accordion-wrapper {
        padding: 100px 0; }

/* Buttons */
#buttons {
    .style-one, .style-two, .style-three, .style-four, .style-five {
        text-align: center; } }

/* Contact Form */

#contact-form {
    .style-one {
        background: #f3f3f3; } }




