
body {
    font-family: $font_primary;
    font-size: 14px;
    line-height: 24px;
    color: #707070;
    background-color: #FFF;
    overflow-x: hidden;
    transition: opacity 0.24s ease-in-out;
    opacity: 1;
    visibility: visible;
    letter-spacing: 0.5px; }

#main_content {
    overflow: hidden;
    z-index: 2;
    background: #fff; }

.footer-fixed {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: -1;
    visibility: visible;
    opacity: 1; }

@media (min-width: 1200px) {

    .container {
        max-width: 1220px; } }


svg {
    display: block; }
a {
    transition: all 0.15s ease-in-out; }
a,
a:hover, a:focus {
    text-decoration: none;
    outline: 0; }

.mb-0 {
	margin-bottom: 0 !important; }

.bg-theme {
	background: $color_theme !important; }

h1,h2,h3,h4,h5 {
    font-family: $font_primary;
    color: #202020;
    font-weight: 600;
    margin: 0 0 5px; }


.section-title {
    margin-bottom: 50px;
    padding: 0 15px;

    h4 {
        font-size: 20px;
        font-style: italic;
        //text-transform: uppercase
        font-weight: 700;
        color: $color_theme;
        position: relative;
        display: inline-block;
        font-family: $font_secandary;

        &:before, &:after {
            position: absolute;
            height: 1px;
            width: 20px;
            background: $color_theme;
            content: '';
            top: 50%; }

        &:before {
         left: -30px; }

        &:after {
            right: -30px; } }

    h2 {
        font-size: 34px;
        font-weight: 800;
        color: #333;
        margin-bottom: 20px;
        position: relative;
        font-family: $font_header;
        overflow: hidden;
        padding-bottom: 20px;

        span {
            font-family: $font_secandary;
            font-style: italic;
            font-weight: 700; }

        &:after {
            position: absolute;
            content: '';
            left: 50%;
            bottom: 0;
            width: 50px;
            height: 2px;
            background: $color_theme;
            transform: translateX(-50%); } }

    p {
        font-size: 15px;
        color: inherit;
        font-weight: inherit;
        position: relative; }

    a {
        margin-top: 30px; }

    &.text-left {
        h4 {
            &:before {
                display: none; } }

        h2 {
            &:after {
                left: 0;
                transform: translateX(0); } } } }

.section-title-two {
    position: relative;
    margin: 40px auto 20px;

    .title_shadow_text {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        opacity: 0.2;
        color: #fff;
        top: -14px;
        line-height: 1;
        letter-spacing: 1px;
        z-index: 1;
        text-shadow: 2px 5px 2px rgba(0, 0, 0, 0.2); } }

.section-title-small {
    h2 {
        font-size: 28px;
        font-weight: 700; }


    p {
        margin-bottom: 30px; } }


.section-dark {
    .section-title {
        h2 {
            color: #FFF; }

        h4 {
            color: #f5f5f5; }

        p {
            color: #f9f9f9; } } }

.section-title {
    &.colorfull {
        h4 {
            color: $color-1;

            &:after ,&:before {
                background: $color-3; } }

        h2 {
            &:after {
                background: $color-2; } } } }

.gp-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 12px 35px;
    color: #333;
    // font-weight: 500
    outline: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover , &:focus {
        background: $color_theme;
        color: #FFF;
        border-color: $color_theme;
        outline: none;
        text-decoration: none; } }

.gp-btn.small {
    padding: 10px 25px; }

.gp-btn.larg {
    padding: 14px 40px; }

.gp-btn {
    &.btn-bradius {
        border-radius: 30px; } }

.gp-btn {
    &.btn-grey {
        background: #f7f7f7;
        box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
        color: #202020;

        &:hover {
            box-shadow: none; } } }

.gp-btn {
    &.btn-dark {
        background: #121212;
        color: #fff;

        &:hover {
            background: $color_theme;
            color: #FFF; } } }

.gp-btn {
    &.btn-light {
        background: #FFF;
        color: #121212;
        box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);

        &:hover {
            background: #121212;
            color: #FFF; } } }

.gp-btn {
    &.btn-primary {
        background: $color_theme;
        color: #FFF;

        &:hover {
            background: #121212;
            color: #FFF; } } }

.gp-btn {
    &.btn-border {
        border: 2px solid $color_theme;
        color: $color_theme;

        &:hover {
            background: $color_theme;
            color: #FFF;
            border-color: $color_theme; } } }

.gp-btn {
    &.color-one {
        background: $color-1;
        color: #FFF;

        &:hover {
            box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba($color-1, 0.46);
            transform: translateY(-1px); } }

    &.color-two {
        background: $color-2;
        color: #FFF;

        &:hover {
            box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba($color-2, 0.46);
            transform: translateY(-1px); } }

    &.color-three {
        background: $color-3;
        color: #FFF;

        &:hover {
            box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba($color-3, 0.46);
            transform: translateY(-1px); } }

    &.color-four {
        background: $color-4;
        color: #FFF;

        &:hover {
            box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba($color-4, 0.46);
            transform: translateY(-1px); } } }

.button {
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 25px;
    background: #e9e9e9;
    color: #333;
    // font-weight: 500
    border: none;
    transition: all 0.3s ease-in-out;
    margin: 0;
    cursor: pointer;

    &:hover {
        background: #333;
        color: #FFF; } }

.gp-btn {
    &.gredient {
        background: $color_gradian;
        color: #FFF;

        &:hover {
            box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(71, 114, 217, 0.6); } } }

.gp-btn {
    &.btn-border-one {
        border: 2px solid $color-1;
        color: $color-1;

        &:hover {
            background: $color-1;
            color: #FFF;
            border-color: $color-1; } }

    &.btn-border-two {
        border: 2px solid $color-2;
        color: $color-2;

        &:hover {
            background: $color-2;
            color: #FFF;
            border-color: $color-2; } }

    &.btn-border-three {
        border: 2px solid $color-3;
        color: $color-3;

        &:hover {
            background: $color-3;
            color: #FFF;
            border-color: $color-3; } } }

.no-border-right {
    border-right: none  !important; }

.no-border-bottom {
    border-bottom: none !important; }

.overlay, .overlay-colorfull, .overlay-dark {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

.overlay {
    background: $color_theme-rgba; }

.overlay-dark {
    background: rgba(0,0,0, 0.8); }

.overlay-colorfull {
    background: rgba($color-1, 0.8); }

.parallax-bg {
    background-size: cover; }

input, textarea, select {
    border: 1px solid #dfdfdf;
    font-size: 16px;
    padding: 8px 15px;
    resize: none;

    &:hover, &:focus {
        outline: none; } }

.section-padding {
    padding: 100px 0; }

@media (min-width: 1200px) {
    width: 1200px; }

@media (max-width: 991px) {
    .section-title {
        p {
            br {
                display: none; } } } }


@media (max-width: 768px) {
    section {
        padding: 70px 0; }

    .section-title {
        h2 {
            font-size: 28px; }

        p {
            font-size: 14px;

            br {
                display: none; } } } }

@media (max-width: 500px) {
    section {
        padding: 50px 0; }

    .section-title {
        h2 {
            font-size: 26px; }

        p {
            font-size: 14px;

            br {
                display: none; } } } }
