#service, #service-colofull, #service-animate {
    padding: 100px 0;
    background: #f8f8f8; }

.featured-tab {
    margin-top: -20px; }


.featured-tab .nav-tabs {
    border: 0; }

.featured-tab .nav-tabs > li {
    width: 25%; }

.featured-tab .nav-tabs > li > a {
    font-weight: 600;
    background: none;
    border-radius: 0;
    min-height: 100px;
    padding: 25px 5px;
    border: 0;
    margin-right: -1px;
    color: #252a37;
    text-align: center;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease; }

.featured-tab .nav-tabs > li > a.active {
    color: #2154cf;
    background: #fff;
    -webkit-box-shadow: 0 -5px 14px -5px rgba(0, 0, 0, 0.05), -5px 0 14px -13px rgba(0, 0, 0, 0.05);
    box-shadow: 0 -5px 14px -5px rgba(0, 0, 0, 0.05), -5px 0 14px -13px rgba(0, 0, 0, 0.05); }


.tab-text-title {
    display: block;
    margin-top: 20px; }


.featured-tab .tab-content {
    background: #fff;
    border: 0;
    border-top: 0;
    padding: 40px 30px;
    min-height: 430px;
    overflow: hidden;
    -webkit-box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.05); }

@media screen and (min-width: 992px) {
    .featured-tab .bg-contain-verticle {
        min-height: 350px; } }



.bg-contain-verticle {
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: middle !important;
    padding: 30px 25px;
    margin: -10px; }


.img-center {
    display: block;
    margin-right: auto;
    margin-left: auto; }


.tab-content-info {
    padding-left: 30px; }

.tab-content-info .btn-light {
    font-weight: 800;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: transparent;
    border-color: transparent; }

.tab-content-info .btn-light i {
    padding-left: 5px; }

.tab-content-info .btn-light:hover {
    color: #2154cf;
    background-color: transparent; }


.tab-content-title {
    font-size: 30px;
    line-height: 22px;
    margin-top: 40px;
    margin-bottom: 30px;
    display: inline-block; }

@media screen and (max-width: 479px) {
    .tab-content-title {
        line-height: 36px; } }



.featured-tab .tab-pane img.pull-left {
    margin: 0 40px 10px 0; }

.featured-tab .tab-pane img.pull-right {
    margin: 0 0 0 40px;


    .featured-tab .nav-link {
        position: relative;
        z-index: 2; }

    .featured-tab .nav-link.active:after {
        position: absolute;
        left: 50%;
        bottom: 0;
        content: "";
        width: 100px;
        height: 3px;
        background: #2154cf;
        margin-left: -50px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s; }

    .featured-tab .nav-link.active .tab-head span {
        color: #2154cf; }


    .tab-head span {
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        color: #222222; }

    .tab-head span i {
        font-size: 45px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        color: #2154cf; } }
