#coming_soon {
	background-size: cover;
	background-position: center center;
	height: 100vh;
	align-items: center;
	display: flex; }


.coming-soon-inner {
	text-align: center;
	h1 {
		font-size: 50px;
		font-family: "Playfair Display", serif;
		color: #fff;
		margin-top: 0;
		margin-bottom: 50px; } }

.countdown {
	text-align: center;
	line-height: 48px;
	font-weight: 700;
	color: #fff;
	font-size: 40px;
	display: block;
	width: 700px;
	height: 100px;
	border-radius: 100px;
	margin: 0 auto 100px;
	z-index: 99;
	padding: 0 6px;
 }	// border: 2px solid #FFF


.CountdownContent {
	width: 125px;
	height: 125px;
	border: 2px solid #ebebeb;
	font-size: 50px;
	font-weight: 700;
	border-radius: 90px;
	text-align: center;
	display: inline-block;
	margin: 0 23px;
	letter-spacing: -1px;
	line-height: 110px;
	float: left;
	position: relative; }

.CountdownContent .CountdownLabel {
	color: #FFF;
	font-size: 11px;
	font-weight: 400;
	text-transform: uppercase;
	display: block;
	letter-spacing: 0;
	line-height: 1;
	margin-top: -25px;
	width: 100%; }

.countdown .CountdownSeparator {
	text-align: center;
	display: inline-block;
	float: left;
	margin-top: 72px;
	position: relative; }


.countdown .CountdownSeparator:after {
	position: absolute;
	left: 0;
	top: -40px;
	content: '';
	background: #FFF;
	height: 50px;
	width: 2px; }


.coming-newslettet {
	width: 50%;
	margin: 50px auto;
	border: 1px solid #fff;
	padding: 5px;
	border-radius: 40px;
	margin-top: 50px;
	height: 60px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;

	input {
		background-color: transparent;
		color: #fff;
		border: 0;
		width: 80%;

		&::placeholder {
			color: rgba(255,255,255,0.5); } }

	.submit-btn {
		background: $color_theme;
		border: 0;
		flex-grow: 1;
		border-radius: 30px;
		color: #fff;
		font-size: 26px;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			background: #fff;
			color: #000; } } }


@media screen and (max-width: 768px) {

	.coming-soon-inner h1 {
		font-size: 35px;
		margin-bottom: 30px; }

	.countdown {
		width: 500px;
		height: 20px; }

	.CountdownContent {
		width: 85px;
		font-size: 40px;
		margin: 0 17px;
		height: 85px;
		line-height: 75px;

		.CountdownLabel {
			margin-top: -17px; } }


	.countdown .CountdownSeparator:after {
		top: -52px; }

	.coming-newslettet {
		margin: 25px auto;
		width: 60%;
		input {
			padding: 10px 25px; } }

	.input-group-btn {
		.btn.btn-large {
			height: 45px;
			font-size: 20px; } } }

@media (max-width: 500px) {

	.coming-soon-inner {
		h1 {
			font-size: 30px; } }

	.countdown {
		width: 350px;
		height: 70px;
		border: none; }


	.CountdownContent {
		width: 80px;
		font-size: 35px;
		margin: 0 2px;
		height: 80px;
		line-height: 70px;


		.CountdownLabel {
			margin-top: -18px;
			font-size: 10px; } }

	.countdown .CountdownSeparator:after {
		display: none; }


	.coming-newslettet {
		width: 100%; } }
