#team {
    padding: 100px 0; }

#team-colorfull {
    background: $color-1; }

.team-member {
    //background: #efefef
    text-align: center;
    transition: all 0.3s ease-in-out;

    .member-thumb {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: auto; }

        .overlay {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($color_theme, 0.7);
            transition: all 0.3s ease-in-out;
            opacity: 0;

            .profile-link {
                margin: 0;
                padding: 0;
                list-style: none;
                position: absolute;
                bottom: -40px;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                transition: all 0.3s ease-in-out;

                li {
                    display: inline-block;
                    margin: 0 2px;

                    a {
                        height: 35px;
                        width: 35px;
                        background: #FFF;
                        display: inline-block;
                        border-radius: 50%;
                        color: #000;
                        transition: all 0.3s ease-in-out;

                        i {
                            line-height: 35px; }

                        &:hover {
                            color: $color_theme; } } } } } }

    .team-details {
        text-align: center;
        padding: 20px;

        .name {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
            color: #000; }

        .position {
            font-size: 15px;
            color: #444;
            line-height: 16px;
            display: block;
            margin-bottom: 10px; } }

    &:hover {
        box-shadow: 0 10px 20px rgba(0,0,0,0.1);
        .member-thumb {
            .overlay {
                opacity: 1;

                .profile-link {
                    bottom: 10px; } } } } }



#team-two {
    padding: 100px 0;
    background: #f5f5f5; }

.team-member-two {
    background: #FFF;
    position: relative;
    border: 1px solid #e8e8e8;
    box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.07), 0 3px 20px 0px rgba(0, 0, 0, 0.05), 0 8px 10px -5px rgba(0, 0, 0, 0.09);

    .member-thumb {
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            transition: all 0.5s ease-in-out;
            transform: scale(1); }

        .team-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(255,255,255,0.5);
                content: '';
                -webkit-transition: -webkit-transform 0.6s;
                transition: transform 0.6s;
                -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
                transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0); } } }

    .team-details {
        padding: 20px;

        .name {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 22px;
            color: #000; }

        .position {
            font-size: 12px;
            display: block;
            margin-bottom: 15px;
            color: #808080; }

        .profile-link {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 10px;
                a {
                    color: #000;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: $color_theme; } } } } }

    &:hover {
        .member-thumb {
            img {
                transform: scale(1.1); }
            .team-overlay {
                &:after {

                    transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0); } } } } }

.team-member-three {
    position: relative;

    .member-thumb {
        img {
            width: 100%;
            transition: all 0.5s ease-in-out;
            transform: scale(1); } }

    .team-details-wrap {
        position: absolute;
        top: 0;
        bottom: 0;
        top: 12px;
        left: 12px;
        bottom: 12px;
        right: 12px;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
        transform: scale(0.5);

        .overlay {
            background: rgba($color_theme,0.9); }

        .team-details {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);




            .name, .position {
                color: #FFF;
                transform: translateY(10px);
                transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
                opacity: 0;
                visibility: hidden; }

            .name {
                text-transform: uppercase; }


            .position {
                margin-bottom: 30px;
                display: block; }


            .profile-link {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    display: inline-block;
                    margin-right: 10px;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
                    transform: translateY(8px) scale(0.8);

                    a {
                        color: #FFF;
                        transition: all 0.3s ease-in-out;
                        font-size: 20px;

                        &:hover {
                            color: #202020; } } } } } }


    &:hover {
        .team-details-wrap {
            box-shadow: 0 0px 30px rgba(0,0,0,0.4);
            opacity: 1;
            visibility: visible;
            transform: scale(1);

            .team-details {
                .name, .position {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0); }

                .name {
                    transition-delay: 0.25s; }

                .position {
                    transition-delay: 0.33s; }

                .profile-link {
                    li {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);

                        &:nth-child(1) {
                            transition-delay: .07692s; }

                        &:nth-child(2) {
                            transition-delay: .15385s; }

                        &:nth-child(3) {
                            transition-delay: .23077s; }

                        &:nth-child(4) {
                            transition-delay: .3092s; } } } } } }


    &.gradiant {
        .team-details-wrap {
            .overlay {
                background: $color_gradian;
                opacity: 0.9; } } }


    &.color-one {
        .team-details-wrap {
            .overlay {
                background: rgba($color-1, 0.9); } } }

    &.color-two {
        .team-details-wrap {
            .overlay {
                background: rgba($color-2, 0.9); } } }

    &.color-three {
        .team-details-wrap {
            .overlay {
                background: rgba($color-3, 0.9); } } }

    &.color-four {
        .team-details-wrap {
            .overlay {
                background: rgba($color-4, 0.9); } } } }



@media screen and (max-width: 992px) {
    .team-member, .team-member-two {
        max-width: 400px;
        margin: 0 auto 20px; } }
