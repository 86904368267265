.product-banner {
	height: 50vh;
	position: relative;

	.breadcrumbs-inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); } }


/* Shop */
#shop, #shop-nosidebar, #shop-sidebar {
	padding: 100px 0;
	background: #fafafa; }

.product-count {
	margin-bottom: 50px;
	padding: 20px;
	border-bottom: 1px solid #eee; }


.form-select {
	border: 2px solid #eee;
	background-color: #fff;
	position: relative;
	border-radius: 30px;
	box-shadow: 0 10px 20px rgba(0,0,0,0.05); }


select.form-control:not([size]):not([multiple]) {
	height: calc(2.25rem + 12px);
	box-shadow: none; }


.form-select select {
	vertical-align: top;
	appearance: none;
	border: 0;
	margin: 0;
	border-radius: 30px;
	outline: none;
	outline: 0; }

.form-control {
	height: 50px;
	padding: 0 15px;
	font-size: 15px;
	line-height: 50px;
	border: 2px solid #eee;
	border-radius: 0;
	box-shadow: none;
	transition: all .3s ease;
	outline: none; }

.form-select:after {
	font: normal normal normal 15px/1 FontAwesome;
	content: "\f107";
	pointer-events: none;
	position: absolute;
	top: 17px;
	right: 1rem; }

.shop-product {
	text-align: center;
	border-radius: 5px;
	background: #fff;
	margin-bottom: 30px;
	box-shadow: 0 8px 20px rgba(0,0,0,.06);

	.product-photo {
		position: relative;
		overflow: hidden;
		img {
			width: 100%;
			height: auto;
			transition: all 0.3s ease-in-out;
			border-top-right-radius: 5px;
			border-top-left-radius: 5px; }

		.alt-thumb {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0; }

		.gp-cart-btn {
			padding: 6px 15px;
			background: #000;
			border-radius: 30px;
			color: #FFF;
			font-size: 11px;
			transition: all 0.5s ease-in-out;
			position: absolute;
			left: 50%;
			bottom: -40px;
			transform: translateX(-50%);
			z-index: 100;


			i {
				margin-right: 5px; }

			&:hover {
				background: $color_theme; } }

		.sold-out {
			display: block;
			position: absolute;
			padding: 6px 22px;
			background: #333;
			color: #FFF;
			left: 52%;
			top: 50%;
			border-radius: 30px;
			font-size: 12px;
			transform: translate(-50%,-50%);
			text-transform: uppercase;
			letter-spacing: 1px; }

		.new-pro {
			padding: 3px 12px;
			font-size: 12px;
			background: $color_theme;
			color: #FFF;
			position: absolute;
			left: 20px;
			top: 20px;
			border-radius: 30px;
			text-transform: uppercase; }

		.sale {
			padding: 3px 12px;
			font-size: 12px;
			text-transform: uppercase;
			font-size: 12px;
			display: block;
			background: #FFF;
			position: absolute;
			right: 20px;
			top: 20px;
			border-radius: 5px;
			color: #000;
			letter-spacing: 1px;

			span {
				color: $color_theme; } } }



	.product-details {
		padding: 20px;

		.product-name {
			font-size: 16px;
			font-weight: 600; }

		.product-price {
			font-size: 15px;
			font-weight: 500;
			color: #444;
			margin-bottom: 10px; }

		.price {
			del {
				margin-right: 20px;
				.amount {
					color: #444; } }
			ins {
				text-decoration: none;

				.amount {
					color: $color_theme; } } }


		.product-rang {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				color: $color_theme; } } }


	&:hover {
		.product-photo {
			.alt-thumb {
				opacity: 1; }

			.gp-cart-btn {
				bottom: 20px; } } } }

/*=========  Single Product  ==========*/
.single-product {
	padding: 100px 0;
	background: #fafafa; }

.column-9 {
	width: 80%;
	margin: 0 auto; }

.single-product-wrapper {
	padding-bottom: 50px;
	border-bottom: 1px solid #e8e8e8;

	.product-title {
		font-size: 28px;
		font-weight: 700;
		color: #333; }

	.product-sub-title {
		font-size: 18px;
		font-weight: 600;
		color: #696969;
		margin-bottom: 20px; } }




.star-rating {
	margin: 0 10px 0 0;
	padding: 0;
	list-style: none;
	float: left;

	li {
		display: inline-block;
		margin-right: 3px;
		color: $color_theme; } }

.review-link {
	color: #444;

	&:hover {
		color: #000; } }

.woocommerce-product-rating {
	.price {
		margin-top: 20px;
		del, ins {
			display: block; }

		del {
			font-size: 20px;
			color: #ccc;
			margin-bottom: 10px; }

		ins {
			font-size: 32px;
			text-decoration: none;
			font-weight: 700;

			color: #000; } } }

.woocommerce-product-details__short-description {
	margin-top: 30px;

	p {
		font-size: 14px;
		color: #7b7b7b;
		margin-bottom: 0;

		span {
			color: #444;
			font-weight: 600; } }

	.product-details-title {
		font-size: 13px;
		text-transform: uppercase;
		color: #333;
		font-weight: 700;
		margin: 20px 0 10px; } }

.cart {
	margin-top: 30px;
	.quantity {
		position: relative;
		background: #FFF;
		box-shadow: 0 2px 20px rgba(0,0,0,0.09);
		width: 80px;
		height: 40px;
		border: none;
		border-radius: 30px;
		float: left;
		padding: 6px 12px;
		margin-right: 10px;

		.fa-minus, .fa-plus {
			font-size: 8px;
			cursor: pointer; }

		input {
			margin: 0;
			width: 50%;
			margin: 0 auto;
			border: none;
			padding: 0;
			text-align: center;
			font-size: 13px;
			margin-left: 5px; } }

	.gp-cart-btn, .add_to_wishlist {
		background: #25abd1;
		padding: 8px 17px;
		color: #FFF;
		border: none;
		border-radius: 30px;
		transition: all 0.3s ease-in-out;
		letter-spacing: 1px;
		font-size: 13px;

		i {
			margin-right: 5px; }

		&:hover {
			background: #333;
			color: #FFF; } }

	.add_to_wishlist {
		background: #fff;
		color: #000;
		padding: 13px 20px;
		box-shadow: 0 5px 20px rgba(0,0,0,0.07);

		i {
			color: #fa6047; }

		&:hover {
			background: #444;
			color: #FFF; } } }

.product_meta {
	margin-top: 30px;

	.sku_wrapper {
		font-size: 15px;
		color: #333;
		display: block;


		span {
			color: #8e8e8e; } }

	.posted_in {
		display: block;
		margin-bottom: 10px;
		a {
			color: #8e8e8e;
			text-decoration: none; } }

	.tagcloud {
		margin-bottom: 15px; } }


.gp-product-tab {
	margin: 0 0 50px;
	padding: 0 0 20px 0;
	list-style: none;
	text-align: center;
	display: block;


	li {
		display: inline-block;

		a {
			padding: 10px 20px;
			text-decoration: none;
			color: #444;
			display: inline-block;
			transition: all 0.3s ease-in-out;
			border-top: 1px solid transparent;

			&:hover, &.active {
				color: $color_theme;
				border-color: $color_theme; } } } }

.comments-title {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 20px; }

.review-list {
	margin: 0;
	padding: 0;
	list-style: none;

	.review-comment {
		margin-bottom: 20px;
		.user-avatar {
			height: 70px;
			width: 70px;
			float: left;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: auto; } }

		.comment-content {
			margin-left: 90px;

			.name {
				font-size: 16px;
				font-weight: 700;
				margin: 0; }

			.comment-meta {
				margin-bottom: 10px;
				.star-rating {
					li {
						font-size: 12px;
						margin-right: 0; } }

				.post-date {
					font-size: 12px;
					color: #a7a7a7; } } } } }

.rating-title {
	font-size: 18px;
	font-weight: 700; }

.choose-ratting {
	.rating-container {
		margin-bottom: 20px;

		.filled-stars {
			color: $color_theme;
			-webkit-text-stroke: 0;
			text-shadow: none; }

		.star {
			font-size: 18px; }

		.caption {
			font-size: 57%;
			margin-top: 0; } } }


.review-form {

	textarea, input {
		border: 1px solid #c9c9c9;
		border-radius: 10px;
		outline: none;
		font-size: 14px;
		width: 100%; }

	textarea {
		height: 150px; }

	input {
		height: 50px;
		margin-top: 20px; }

	.gp-btn {
		max-width: 250px;
		margin: 30px 0  0 15px;
		display: inline-block;
		width: 100%;
		text-align: center;
		background: #333;
		color: #FFF;
		border-radius: 30px;
		padding: 10px 0px;
		transition: all 0.3s ease-in-out;
		border: none;
		outline: none;

		&:hover {
			background: $color_theme; } } }


.shop_attributes {
	width: 100%;
	tbody {
		border: 1px solid #dcdcdc;
		tr {
			height: 40px;
			border-bottom: 1px solid #dcdcdc;


			th {
				width: 30%;
				border-right: 1px solid #dcdcdc;
				padding-left: 20px; }

			td {
				padding-left: 20px;
				p {
					margin-bottom: 0; } } } } }


.related-product {
	padding-top: 30px;
	margin-top: 30px;
	border-top: 1px solid #dcdcdc;

	.product-title {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 30px;
		color: #000; } }

/* Product Widgets */

#slider-range {
	margin-bottom: 15px; }

.ui-widget-header {
	background: $color_theme; }

.ui-slider-horizontal {
	height: 7px;

	.ui-slider-handle {
		top: -6px;
		border-radius: 50%; } }

.ui-state-default, .ui-widget-content .ui-state-default {
	border: 3px solid $color_theme;
	background: #FFF;
	outline: none; }

.ui-widget-content {
	background: #dcdcdc; }

.ui-widget {
	&.ui-widget-content {
		border: none; } }

.price-filter {
	span {
		font-size: 13px;
		color: #000;
		#amount {
			width: auto;
			padding: 0;
			margin: 0;
			outline: none;
			font-size: 13px;
			border: none;
			cursor: default;
			background: transparent; } } }

/* Product List */
.product_list_widget {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #efefef;

		&:last-child {
			border-bottom: none; }

		.product-details {
			color: #333;
			font-weight: 600;
			.product-thumb {
				float: right;
				height: 65px;
				width: 65px; }

			.product-title {
				display: block;
				line-height: 16px;
				transition: all 0.3s ease-in-out;

				&:hover {
					color: $color_theme; } } }

		.product-rating {
			height: 1.7em;
			overflow: hidden;
			.star-rating {
				height: 1.7em;
				li {
					font-size: 12px;
					margin-right: -2px; } } }

		ins {
			margin-left: 5px;
			text-decoration: none; } } }


/* Cart */
#cart {
	padding: 100px 0;
	background: #f7f7f7; }

.woocommerce {
	table {
		&.shop_table {
			margin-bottom: 60px;
			border: none;
			border-collapse: collapse;
			background: #fff;
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 15px 34px rgba(0, 0, 0, 0.09);
			margin: 0 -1px 24px 0;
			text-align: left;
			width: 100%;

			th {
				border: 0;
				text-transform: uppercase;
				position: relative; }

			td {
				padding: 6px 12px;
				border: 0;
				text-transform: uppercase;
				position: relative; }

			thead {
				tr {
					background: #f7f7f7;

					th {
						border-bottom: 1px solid #f7f7f7;
						background: #e9e9e9;
						font-size: 15px;
						font-weight: 700;
						line-height: 28px;
						letter-spacing: 0.1em;
						padding: 15px 30px;
						text-align: center; } } }

			tbody {
				tr {
					border-bottom: 1px solid #f7f7f7;

					td {
						&.product-item {
							a {
								color: #202020;
								text-decoration: none;
								font-weight: 600;

								&.remove {
									display: inline-block;
									vertical-align: middle;
									width: 40px;
									height: 40px;
									font-size: 16px;
									line-height: 40px;
									text-align: center;
									background: #e9e9e9;
									transition: all 0.4s ease-in-out;
									position: absolute;
									left: 20px;
									top: 20px;
									border-radius: 100%;
									color: red !important;
									text-decoration: none;
									border: 0; } }

							img {
								width: 70px;
								height: 70px;
								margin-left: 70px;
								margin-right: 15px;
								border-radius: 3px; } }

						&.product-price {
							line-height: 28px;
							letter-spacing: 0.1em;
							text-align: center;
							border: 1px solid #f7f7f7; }

						&.product-subtotal {
							text-align: center;

							span {
								display: inline-block; } }

						&.actions {
							padding: 30px 20px;

							.coupon {
								float: left;
								input {
									margin-bottom: 0;
									&.input-text {
										margin-right: 10px;
										max-width: 200px;
										padding: 10px 15px;
										border: 2px solid #f7f7f7;
										border-radius: 25px;
										font-size: 14px; }

									&.button {
										background: #333;
										color: #FFF;

										&:hover {
											background: #191919;
											color: #FFF; } } } }

							.button {
								box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18); } } } } } } }

	.cart-collaterals {
		.cart_totals {
			.grand-total {
				.total-wrap {
					padding: 20px;
					background: #fff;
					border-radius: 15px;

					> ul {
						margin: 0;
						padding: 0;

						> li {
							list-style: none;
							padding: 10px 0;
							border-bottom: 1px solid #f7f7f7;

							.title {
								display: inline-block;
								width: 59%;
								font-size: 12px;
								font-weight: 900;
								line-height: 30px;
								letter-spacing: 0.1em;
								text-transform: uppercase; }

							span[data-title="Subtotal"],  span[data-title="Total"] {
								display: inline-block;
								width: 39%;
								font-size: 14px;
								font-weight: 600;
								font-style: italic;
								line-height: 24px;
								letter-spacing: 0.1em;
								text-align: right; } } } } } } }

	.grand-total {
		h4 {
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 700;
			color: #333;
			margin-bottom: 20px; } }


	.wc-proceed-to-checkout {
		padding-top: 20px;
		.gp-btn {
			padding: 12px 35px;
			color: #FFF;
			font-size: 14px;
			display: inline-block;
			margin: auto;
			float: none;
			text-align: center;
			border-radius: 25px;
			background: $color_theme;
			font-size: 14px;
			border: none;
			box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);

			&:hover {
				background: #333;
				color: #fff; } } } }

.rating {
  margin-bottom: 20px;
  padding: 0; }


.rating li {
  cursor: default;
  display: inline-block;
  font-size: 20px;
  list-style-type: none;
  cursor: pointer; }


.star,
.rating:not(.vote-cast):hover .star:hover ~ .star,
.rating.vote-cast .star.selected ~ .star {
  color: #a5a5a5; }


.rating:hover .star,
.rating.vote-cast .star {
  color: $color_theme; }


#checkout {
	padding: 100px 0;
	form {
		&.checkout {
			&.woocommerce-checkout {
				background: #fff;
				padding: 30px;
				border-radius: 10px;
				box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18); } }

		input, textarea {
			&::placeholder {
				color: #c7c7c7; } } } }

.create-account {
	.checkbox {
		display: inline-block;
		margin-left: 10px; } }

.woocommerce-checkout {
	#order_review {
		background: #f7f7f7;
		padding: 30px;
		border: 0;
		border-radius: 10px;
		overflow: hidden;

		.shop_table {
			> ul {
				margin: 0;
				padding: 0;
				list-style: none;

				> li {
					list-style: none;
					padding: 15px 0;
					border-top: 1px solid #f7f7f7;


					&.header {
						font-size: 14px;
						font-weight: 700;
						line-height: 24px;
						letter-spacing: 0.1em;
						text-transform: uppercase;
						border-bottom: 1px solid; }

					&.order-total {
						font-size: 14px;
						font-weight: 700;
						line-height: 24px;
						letter-spacing: 0.1em;
						text-transform: uppercase;
						border-top: 1px solid; } }


				li {
					font-size: 12px;
					font-weight: 700;
					text-transform: uppercase;

					.col-left {
						display: inline-block;
						width: 59%; }

					.col-right {
						display: inline-block;
						width: 38%;
						text-align: right; } } } }

		.order-review-heading, .payment-method-heading {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 20px;
			text-transform: uppercase;
			color: #202020; }

		#payment {
			margin-top: 50px;

			.button {
				padding: 10px 40px;
				cursor: pointer;
				margin-top: 10px; } } } }

.wc_payment_methods {
	margin: 0;
	padding: 0;
	list-style: none; }


.form-row-first, .form-row-last {
	width: 48%;
	float: left; }

.form-row-first {
	margin-right: 38px; }

.input-text, select {
	width: 100%;
	border-radius: 4px; }

.form-row {
	margin-bottom: 20px; }


@media (max-width: 1200px) {
	.form-row-first {
		margin-right: 30px; } }

@media (max-width: 992px) {
	.form-row-first {
		margin-right: 27px; } }

@media (max-width:500px) {
	.form-row-first, .form-row-last {
		margin-right: 0;
		width: 100%; } }
