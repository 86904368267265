#pricing {
    padding: 100px 0; }


.gp-price-table-one, .gp-price-table-two {
    background-color: rgb(239,239,239);
    border-radius: 15px;
    max-width: 406px;
    width: 100%;

    .price-inner {
        .currency {
            font-size: 25px;
            vertical-align: top;
            line-height: 0;
            font-weight: 900;
            line-height: 17px;
            color: #202020; }

        .price {
            font-size: 60px;
            font-weight: 700;
            margin-bottom: 20px;
            display: inline-block;
            line-height: 40px;
            color: #202020; } }

    .price-table-container {
        border-radius: 15px;
        display: inline-block;
        text-align: center;
        width: 100%;
        max-width: 377px;
        box-shadow: 0 1px 1px rgba(0,0,0,0.1);
        transition: all .3s;
        margin-top: 4px;
        padding: 50px 30px 30px 35px;

        .title {
            font-size: 20px;
            font-weight: 700;
            color: $color_theme;
            position: relative;
            margin-bottom: 30px;
            padding-bottom: 30px;
            display: inline-block;

            &:after {
                position: absolute;
                content: '';
                width: 40px;
                height: 2px;
                background: $color_theme;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%); } }

        .description {
            margin: 0 0 35px;
            padding: 0;
            list-style: none;

            li {
                line-height: 36px;
                font-size: 16px;
                color: #000;
                font-weight: 600; } }

        .gp-btn {
            padding: 14px 40px;
            background: #FFF;
            display: inline-block;
            color: #000;
            font-weight: 700;
            border-radius: 30px;
            width: 180px;
            border: none;

            &:hover {
                background: $color_theme;
                color: #FFF; } }
        &:hover {
            box-shadow: 0 20px 25px rgba(0,0,0,0.15);
            margin-top: 0; } }

    &.color-active {
        background: $color-1;

        .price-table-container {
            .title {
                color: #FFF; }

            .description {
                li {
                    color: #FFF; } } }

        .price-inner {
            .currency, .price {
                color: #FFF; } } }

    &.color-one {
        &:hover {
            background: $color-2;

            .price-table-container {
                .title {
                    color: #FFF; }

                .description {
                    li {
                        color: #FFF; } } }

            .price-inner {
                .currency, .price {
                    color: #FFF; } } } }

    &.color-two {
        &:hover {
            background: $color-3;

            .price-table-container {
                .title {
                    color: #FFF; }

                .description {
                    li {
                        color: #FFF; } } }

            .price-inner {
                .currency, .price {
                    color: #FFF; } } } } }


/* Pricing two */

#pricing-two {
    padding: 100px 0;
    background: #f5f5f5; }

.gp-price-table-two {
    background: rgba(0,0,0,0.03);


    .price-table-container {
        max-width: 406px;
        margin-top: 0;
        box-shadow: none; }

    .title-inner {
        margin-bottom: 40px;

        .title {
            font-size: 18px;
            margin-bottom: 0px;
            padding-bottom: 0;
            color: #333;

            &:after {
                display: none; } }

        span {
            display: block;
            color: #444; } }

    .price-inner {
        margin-bottom: 40px;

        .price {
            display: block;
            font-size: 60px;
            font-weight: 700;
            margin-bottom: 10px; }

        .price-time {
            font-size: 15px;
            font-weight: 500; } }

    .description {
        li {
            font-size: 14px !important;
            position: relative;
            font-weight: 400;

            i {
                margin-right: 10px;
                font-size: 14px;
                color: $color_theme; } } }

    .gp-btn {
        background-color: transparent;
        border: 2px solid $color_theme;
        color: $color_theme;
        padding: 14px 40px;

        &:hover {
            background-color: $color_theme;
            color: #FFF; } }

    &:hover {

        .price-table-container {
            box-shadow: none; } }

    &.active {
        background: #FFF;
        border-radius: 0;
        position: relative;

        &:before, &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 30px;
            background: #FFF; }

        &:before {
            top: -20px;
            left: 0;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px; }

        &:after {
            bottom: -20px;
            left: 0;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px; }

        .price-table-container {
            box-shadow: 5px 8.7px 40px rgba(99,117,138,0.3);

            .gp-btn {
                background: $color_theme;
                color: #FFF;
                box-shadow: 4px 6.9px 16px rgba($color_theme, 0.4);

                &:hover {
                    background: rgba($color_theme, 0.8);
                    box-shadow: 4px 6.9px 16px rgba($color_theme, 0.6);
                    color: #FFF; } } } } }

/* Pricing Table Gradient */
.gp-price-table-two {
    z-index: 1;

    &.gradiant {
        background: $color_gradian;

        .price-table-container {
            box-shadow: none;

            .title-inner {
                .title {
                    color: #FFF; }

                span {
                    color: #efefef; } }

            .price-inner {
                .price {
                    color: #FFF; }

                .price-time {
                    color: #dedede; } }

            .price-table-container {
                box-shadow: none; }

            .description {
                li {
                    color: #FFF;

                    i {
                        color: $color_theme; } } } }
        &.active {
            background: #fc4a1a;
            background: -webkit-linear-gradient(to bottom, #f7b733, #fc4a1a);
            background: linear-gradient(to bottom, #f7b733, #fc4a1a);
            transform: scale(1.07);
            border-radius: 20px;
            z-index: 10;

            &:after, &:before {
                background: #fc4a1a;
                background: -webkit-linear-gradient(to bottom, #f7b733, #fc4a1a);
                background: linear-gradient(to bottom, #f7b733, #fc4a1a);
                display: none; } } } }



@media screen and (max-width: 992px) {
    .gp-price-table-one, .gp-price-table-two {
        margin: 0 auto 40px; } }

@media screen and (max-width: 768px) {


    .price-table-container {
        padding-bottom: 20px; } }
