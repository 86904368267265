#newsletter {
    padding: 100px 0;
    background: #f5f5f5;
    position: relative; }

.input-group-inner {
    input, textarea, select {
        margin: 0;
        border-radius: 4px 0 0 4px;
        border: none;
        padding: 15px 25px;
        background: transparent;
        color: #202020;
        width: 100%;

        &:focus {
            outline: none; } } }

.newsletter-form {

    .input-group-inner {
        border: 1px solid #fff;
        border-radius: 40px;
        padding: 3px;
        background: #FFF;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex; } }

.input-group-btn {
    .btn {
        border-radius: 0 4px 4px 0;

        &.btn-large {
            line-height: 2px;
            height: 62px;
            padding: 15px 25px!important; } } }



.subscribe-title {
    font-size: 34px;
    font-weight: 800;
    margin-bottom: 20px;
    color: #FFF; }

.sub-content {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
    color: #f5f5f5; }



.center-col {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important; }

.input-group-btn {

    .btn.btn-large {
        line-height: 2px;
        height: 55px;
        padding: 12px 50px!important;
        color: #fff;
        font-size: 30px;
        background: $color_theme;
        transition: all 0.3s ease-in-out;
        border-radius: 40px;

        &:hover {
            background: #333; } } }
