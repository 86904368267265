// Fonts
$font_primary: 'Poppins', sans-serif;
$font_header: 'Catamaran', sans-serif;
$font_secandary: 'Playfair Display', serif;

// Color
$color_theme: #03a9f4;
$color_theme-rgba: rgba($color_theme, 0.8);
$color_theme-2: #ffe200;
$color_theme-2-rgba: rgba($color_theme, 0.8);
$color_text: #6d6c6c;
$color_header: #2c3e50;
$color_rgba: rgba(53, 152, 219, 0.8);
$color_gradian: linear-gradient(180deg,#4772d9,#6d47d9);


$color-1: #D81B60;
$color-2: #9c27b0;
$color-3: #00C851;
$color-4: #f96332;
$color-5: #ffe200;
