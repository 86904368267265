#portfolio-two, #portfolio-two-colorfull {
    background: #f5f5f5; }

.grid-item {
    float: left; }

.grid-item img {
    width: 100%;
    height: auto; }

.gp-isotope:before, .gp-isotope:after {
    display: table;
    content: ""; }

.gp-isotope:after {
    clear: both; }

.grid-sizer, .grid-item {
    width: 25%; }

.grid-item {
    overflow: hidden;
 }    //margin-bottom: 20px

.gp-isotope-filter {
    margin: 0 0 40px;
    text-align: center;
    padding: 0; }

.gp-isotope-filter > li {
    display: inline-block;
    padding-left: 0;
    padding-right: 10px; }

.gp-isotope-filter > li > a {
    position: relative;
    font-size: 13px;
    transition: all .3s;
    text-transform: uppercase;
    color: #666;
    font-weight: 500;
    padding: 8px 18px;
    border-radius: 30px;
    display: inline-block; }

.gp-isotope-filter > li > a:after {
    content: '';
    -webkit-transition: all .3s;
    transition: all .3s; }

.gp-isotope-filter > li:hover a {
    background: #f5f5f5;
    color: #202020; }

.gp-isotope-filter > li.current a, {

    color: #FFF;
    text-decoration: none;
    background: $color_theme; }

.gp-portfolio-items {
    padding: 0;
    margin-bottom: 0;
    list-style: none; }

.gp-portfolio-item {
    .portfolio_details_wrap {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        color: #FFF;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        &:before {
            content: '';
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            transition: all .3s;
            text-align: center;
            opacity: 1;
            background: rgba($color_theme, 0.9);
            position: absolute;
            margin: 15px;
            transform: scale(0.5);
            box-shadow: 0 0 30px rgba(0,0,0, 0.5);
            transition: all 0.5s cubic-bezier(.05,-0.17,.39,1.65); }


        .portfolio-info {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transition: all 0.3s ease-in-out;
            transform: translate(-50%, -50%);

            h4 {
                color: #FFF;
                font-size: 22px; }

            .popup-modal {
                //position: relative
                height: 40px;
                width: 40px;
                line-height: 28px;
                text-align: center;
                border: 1px solid #FFF;
                border-radius: 5px;
                color: #FFF;
                font-size: 13px;
                border-radius: 50%;
                display: block;
                margin: 0 auto 20px;
                transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
                transform: translateY(-20px);
                //top: -20px
                opacity: 0;
                visibility: hidden;

                i {
                    line-height: 37px;
                    font-size: 18px; }

                &:hover {
                    background: #202020;
                    border-color: #202020;
                    text-decoration: none;
                    color: #FFF; } }

            .portfolio-title {

                margin-bottom: 0;
                font-size: 20px;
                transition: all 0.33s cubic-bezier(0.23, 0.88, 0.34, 0.99);
                top: 20px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(10px); }

            .portfolio-categorie {
                transform: translateY(15px);
                transition: all 0.7s cubic-bezier(0.23, 0.88, 0.34, 0.99);
                top: 20px;
                opacity: 0;
                display: inline-block;
                visibility: hidden; } } }

    &:hover {
        .portfolio_details_wrap {
            opacity: 1;

            &:before {
                transform: scale(1);
                margin: 20px; }

            .portfolio-info {
                .popup-modal, .portfolio-title, .portfolio-categorie {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible; }

                .portfolio-title {
                    transition-delay: 0.25s; }

                .portfolio-categorie {
                    transition-delay: 0.33s; } } } } }



.gp-isotope-grid__img {
    overflow: hidden; }

.gp-isotope-1 .gp-isotope-filter {
    display: block; }

.gp-isotope-1 .grid-item {
    padding: 7px; }


.gp-isotope-1 .gp-isotope-grid {
    margin-bottom: 44px; }

/* Portfolio Colorfull */
.gp-isotope-filter {
    &.colorfull {
        > li {
            &.current {
                a {
                    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba($color-2, 0.6);
                    background: $color-2; } } } } }

.gp-portfolio-item {
    &.color-one {
         .portfolio_details_wrap {
            &:before {
                background: rgba($color-1, 0.9); } } } }

.gp-portfolio-item {
    &.color-two {
         .portfolio_details_wrap {
            &:before {
                background: rgba($color-2, 0.9); } } } }

.gp-portfolio-item {
    &.color-three {
         .portfolio_details_wrap {
            &:before {
                background: rgba($color_theme, 0.9); } } } }

.gp-portfolio-item {
    &.color-four {
         .portfolio_details_wrap {
            &:before {
                background: rgba($color-3, 0.9); } } } }

.gp-portfolio-item {
    &.color-five {
         .portfolio_details_wrap {
            &:before {
                background: rgba($color-4, 0.9); } } } }

.gp-portfolio-item {
    &.color-six {
         .portfolio_details_wrap {
            &:before {
                background: rgba($color-5, 0.9); } } } }


/* Portfolio Gredient */
.gp-isotope-filter {
    &.greadient {
        > li {
            &.current {
                a {
                    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(71,114,217, 0.6);
                    background: $color_gradian; } } } } }

.gp-portfolio-item {
    &.gredient {
        .portfolio_details_wrap {
            &:before {
                background: $color_gradian;
                opacity: 0.9; } } } }


@media screen and (max-width: 1024px) {
    .grid-sizer, .grid-item {
        width: 33%; } }


@media screen and (max-width: 768px) {
    .grid-sizer, .grid-item {
        width: 50%; } }

@media screen and (max-width: 500px) {
    .grid-sizer, .grid-item {
        width: 100%; }

    .gp-isotope-filter {
        > li {
            padding-right: 5px;
            > a {
                font-size: 10px;
                padding: 3px 9px; } } } }
